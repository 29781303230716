<template>
  <div class="contact-us">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-10 pad-top-35">
        <h4 class="text-center">CONTACT US</h4>
        <p class="text-center txt-grey">You can reach out us through below details. Never hesitate on asking a question.</p>
      </div>
    </div>
    <div class="row margin-0 justify-content-center">
      <div class="col-md-4 pad-30">
          <h3><span class="fa fa-map-marker"></span> Reach us on</h3>
          <br>
          <p><span class="fa fa-envelope"></span> : info@ebechana.com</p>
          <p><span class="fa fa-mobile"></span> : +91 8985948415</p>
      </div>
      <div class="col-md-7">
          <div class="contact pad-top-20">
              <br>
              <h3 class="txt-grey">Drop a message here.</h3>
              <div class="row margin-top-30">
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Full Name<span class="req">*</span></label>
                          <input type="text" v-model="payload.name" class="form-control" placeholder="Full Name">
                          <span class="error" v-show="error.name">{{error.name}}</span>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Email Address<span class="req">*</span></label>
                          <input type="text" v-model="payload.email" class="form-control" placeholder="Email Address">
                          <span class="error" v-show="error.email">{{error.email}}</span>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Mobile Number<span class="req">*</span></label>
                          <input type="text" v-model="payload.mobile" class="form-control" placeholder="Mobile Number">
                          <span class="error" v-show="error.mobile">{{error.mobile}}</span>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Place</label>
                          <input type="text" v-model="payload.place" class="form-control" placeholder="Your Place">
                          <span class="error" v-show="error.place">{{error.place}}</span>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label for="">Message<span class="req">*</span></label>
                          <textarea v-model="payload.message" class="form-control" placeholder="Your Message here"></textarea>
                          <span class="error" v-show="error.message">{{error.message}}</span>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <button class="btn btn-primary" @click="submitContact()">SUBMIT</button>
                      &nbsp;&nbsp;&nbsp;<span class="success">{{smessage}}</span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            smessage: '',
            payload: {
                name: '',
                email: '',
                place: '',
                mobile: '',
                message: '',
                type: 'contact',
            },
            error: {
                name: '',
                email: '',
                mobile: '',
                message: '',
            }
        }
    },
    created() {
        document.title = "Contact Us - EBechana"
        this.resetPayload();
    },
    methods: {
        submitContact() {
            const that = this;
            if (that.validate()) {
                that.$cs.contact.create({data:that.payload})
                .then(res=>{
                    that.smessage = res.data;
                    that.resetPayload();
                }, err=>{
                    console.log(res.response.data)
                })
            }
        },
        validate() {
            this.smessage = '';
            this.error= {
                name: '',
                email: '',
                mobile: '',
                message: '',
            }
            var proceed = true;
            if (this.payload.name === '') {
                this.error.name = 'Full Name is required';
                proceed = false;
            }
            if (this.payload.email === '') {
                this.error.email = 'Email is required';
                proceed = false;
            }
            if (this.payload.mobile === '') {
                this.error.mobile = 'Mobile number is required';
                proceed = false;
            }
            if (this.payload.message === '') {
                this.error.message = 'Message is required';
                proceed = false;
            }
           return proceed; 
        },
        resetPayload() {
            this.payload= {
                name: '',
                email: '',
                place: '',
                mobile: '',
                message: '',
                type: 'contact',
            },
            this.error= {
                name: '',
                email: '',
                mobile: '',
                message: '',
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.terms
  padding-top 6vh
  min-height 100vh
  background-color #ffffff
  .about-block
    background-color #ffffff
    padding 20px 14px
    border-radius 2px
.contact-us
    background-color white
    min-height 100vh
    .contact
        padding 20px 0px
        font-size 1.3em
        h2
            margin-bottom 10px
.press-back
    background url('https://images.unsplash.com/photo-1495106245177-55dc6f43e83f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')
    background-size cover
    background-position 100% 55%
    .press-gradient
        background: #BA8B02;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #181818, rgba(0,0,0,0.1));  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #181818, rgba(0,0,0,0.1)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color #ffffff
        padding 40px 30px
        // min-height 20vh
@media (max-width: 767px)
    .contact
        padding 10px!important
        font-size 1em
        h2
            margin-bottom 10px
            font-size 1.3em
        p
            font-size 0.7em
</style>
